import Numeral from 'numeral';
import Moment from 'moment';
import Constants from "@/constants";
import eventBus from "@/events";
import Token from "@/token";
import Companies from "@/companies";

export const CapitalFilter = {
    filters: {
        capitalize(val) {
            return val.charAt(0).toUpperCase() + val.slice(1);
        }
    }
}

export const Debug = {
    data() {
        return {
            debug: Constants.DEBUG
        }
    }
}

export const IsBusy = {
    data() {
        return {
            isBusy: false,
            saving: false,
            areBusy: []
        }
    }
};

export const Company = {
    data() {
        return {
            currentCompany$: undefined
        }
    },
    mounted() {
        this.currentCompany$ = Companies.currentCompany;
    },
    computed: {
        currentCompany() {
            return this.currentCompany$;
        },
        hasCompany() {
            return !!this.currentCompany$;
        }
    },
};

export const Security = {
    data() {
        return {
            isAuthenticated: false,
        }
    },
    mounted() {
        eventBus.$on(Constants.EVENT_WHEN_USER_COMES_BACK, () => {
            this.isAuthenticated = true;
        });

        eventBus.$on(Constants.EVENT_WHEN_LOGOUT, () => {
            this.isAuthenticated = false;
        });
    }
}

export const SendsMessages = {
    mixins: [CapitalFilter],
    methods: {
        sendMessage(message, params, type, error) {
            eventBus.$emit(Constants.EVENT_WHEN_MESSAGE_DISPLAY, message, {
                ...params, ...{
                    catalog: this.catalog,
                    gender: this.gender
                }
            }, type, error)
        },
        sendError(message, params, error) {
            this.sendMessage(message, params, 'danger', error);
        },
        sendInfo(message, params) {
            this.sendMessage(message, params, 'info');
        },
        sendWarning(message, params) {
            this.sendMessage(message, params, 'warning');
        }
    },
    computed: {
        gender() {
            return this.$t(this.catalog[this.catalog.length - 1] === 'a' ? 'FEMININE_GENDER' : 'MALE_GENDER')
        },
        operation() {
            const split = this.$route.name.split('_');
            return this.$t(split[split.length - 2]);
        },
        catalog() {
            const split = this.$route.name.split('_');
            return this.$t(split[0]);
        },
        catalogs() {
            const split = this.$route.name.split('_');
            const diff = split.length - 4;
            let nameA = [];
            for (let i = 0; i <= diff; i++) {
                nameA.push(split[i]);
            }
            return this.$t(nameA.join('_').plural());
        }
    }
}

export const LoadData = {
    mixins: [IsBusy, Debug],
    async mounted() {
        if (Token.isNotAuthenticated()) {
            eventBus.$on(Constants.EVENT_WHEN_USER_COMES_BACK, async () => {
                await this.loadData();
            });
        } else {
            await this.loadData();
        }
    },
    methods: {
        async loadData() {
            this.isBusy = true;

            return new Promise((res) => {
                this.isBusy = false;
                res(true);
            });
        }
    }
}

export const NumberFilter = {
    filters: {
        number(number, format) {
            format = format || Constants.NUMBER_FORMAT;
            return Numeral(number).format(format);
        },
        currency(number, format) {
            format = format || Constants.CURRENCY_FORMAT;
            return Numeral(number).format(format);
        },
        percentage(number, format) {
            format = format || Constants.PERCENTAGE_FORMAT;
            return Numeral(number).format(format);
        }
    },
    methods: {
        getNumericValue(formattedValue) {
            return Numeral(formattedValue).value();
        }
    }
}

export const Filter = {
    mounted() {
        eventBus.$on('CLEAR_FILTER', () => {
            this.filter = {};
        });
    }
}

export const DateFilter = {
    filters: {
        date(date, format) {
            format = format || Constants.DATE_FORMAT;
            return Moment(date).format(format);
        }
    },
    methods: {
        getDate(date, format) {
            format = format || Constants.DATE_FORMAT;
            return Moment(date, format).date();
        }
    }
}

export const Table = {
    mixins: [IsBusy, Debug, SendsMessages],
    props: ['onlyActive'],
    methods: {
        setFilter: function (filter) {
            this.filter = filter;
            this.refresh();
        },
        clear() {
            this.filter = {status: this.onlyActive ? true : undefined};
            eventBus.$emit('CLEAR_FILTER');
        },
        add: function () {
            this.$router.push({name: this.registerPage});
        },
        edit: function (id) {
            this.$router.push({name: this.editPage, params: {id}});
        },
        details() {

        },
        refresh: function () {
            this.tableRef.refresh()
        },
        getRowIdx(id) {
            return this.items.map(x => x.id).indexOf(id);
        },
        getRow(id) {
            const idx = this.getRowIdx(id);
            return this.items[idx];
        },
        removeRow(id) {
            const idx = this.getRowIdx(id);
            this.items.splice(idx, 1);
        },
        showFileModal(id) {
            this.id = id;
            this.$bvModal.show('digital-file-modal');
        },
        handleSelection(selected) {
            this.$emit('change', selected);
        },
        isChecked: function (data) {
            const href = data.item.id;
            const idx = this.originals.indexOf(href);
            if (idx !== -1) {
                this.originals.splice(idx, 1);
                this.selectedCount++;
                data.selectRow();
            }
            return data.rowSelected;
        },
        toggleRow(data) {
            data.rowSelected ? data.unselectRow(data.index) : data.selectRow(data.index);
        },
        selectAll() {
            this.allSelected ? this.tableRef.clearSelected() : this.tableRef.selectAllRows();
        },
        remove: async function (id, identifier) {
            if (confirm(this.$t('LIST_CONFIRM_DELETE', {catalog: this.catalog, identifier}))) {
                try {
                    this.isBusy = true;
                    await this.controller.deleteById(id);
                    this.sendMessage('LIST_DELETE_OK', {catalog: this.catalog, identifier});
                    this.removeRow(id);
                } catch (e) {
                    console.error(e);
                    this.sendError('LIST_DELETE_ERROR', {catalog: this.catalog, identifier})
                } finally {
                    this.isBusy = false;
                }
            }
        },
        enable: async function (id, identifier) {
            if (confirm(this.$t('LIST_CONFIRM_ENABLE', {catalog: this.catalog, identifier}))) {
                try {
                    this.isBusy = true;
                    await this.controller.enable(id);
                    this.sendMessage('LIST_ENABLE_OK', {catalog: this.catalog, identifier});
                    this.getRow(id).enabled = true;
                } catch (e) {
                    console.error(e);
                    this.sendError('LIST_ENABLE_ERROR', {catalog: this.catalog, identifier})
                } finally {
                    this.isBusy = false;
                }
            }
        },
        disable: async function (id, identifier) {
            if (confirm(this.$t('LIST_CONFIRM_DISABLE', {catalog: this.catalog, identifier}))) {
                try {
                    this.isBusy = true;
                    await this.controller.disable(id);
                    this.sendMessage('LIST_DISABLE_OK', {catalog: this.catalog, identifier});
                    this.getRow(id).enabled = false;
                } catch (e) {
                    console.error(e);
                    this.sendError('LIST_DISABLE_ERROR', {catalog: this.catalog, identifier})
                } finally {
                    this.isBusy = false;
                }
            }
        }
    },
    mounted() {
        this.page = +this.$route.query.page || 1;
        this.size = +this.$route.query.size || 20;
        this.sort = this.$route.query.sort || '';
        this.id = this.$route.params.id;

        if (this.tableRef) {
            this.tableRef.$on('row-selected', (data) => {
                this.selectedCount = data.length;
                this.allSelected = this.items.length === data.length;
            });
        }

        eventBus.$on('COMPANY_CHANGED', () => {
            if (this.tableRef) {
                this.tableRef.$on('row-selected', (data) => {
                    this.selectedCount = data.length;
                    this.allSelected = this.items.length === data.length;
                });
            }
        });
    },
    data() {
        return {
            id: undefined,
            items: [],
            page: 1,
            size: 20,
            sort: 'username',
            filter: {status: this.onlyActive ? true : undefined},
            form: {},
            endpoint: '/users',
            totalRows: 0,
            registerPage: '',
            editPage: '',
        }
    },
    computed: {
        tableRef() {

        },
        controller() {

        },
        pageTotals() {
            return {
                page: this.page,
                totalRows: this.totalRows,
                size: this.totalRows < this.size ? this.totalRows : this.size,
                totalPages: Math.ceil(this.totalRows / this.size)
            }
        },
        listAll() {
            return this.controller.findAll;
        }
    }
}

export const Form = {
    mixins: [LoadData, SendsMessages],
    async mounted() {
        if (this.$route.params.id) {
            this.id = this.$route.params.id;
            await this.getData();
        }
    },
    methods: {
        set(field, value) {
            this.form[field] = value
            this.$v.form[field].$touch()
        },
        reset(field) {
            this.$v.form[field].$reset();
        },
        getData() {
            return new Promise(r => r());
        },
        beforeSave(form) {
            form.$touch();
            if (form.$invalid) {
                if (Constants.DEBUG) {
                    console.log(form);
                }
                throw new Error('Form is invalid');
            }
        },
        async save() {
            try {
                this.saving = true;
                this.beforeSave(this.$v.form);
                const saved = await this.doSave(this.id, this.form);
                this.afterSave(saved.data);
            } catch (e) {
                this.saveError(e);
            } finally {
                this.saving = false;
            }
        },
        async doSave(id, data) {
            return id ? await this.controller.update(id, data) : await this.controller.create(data);
        },
        afterSave() {
            this.sendMessage(this.id ? 'FORM_EVENT_UPDATED' : 'FORM_EVENT_CREATED', this.form);
            this.$router.go(-1)
        },
        saveError(e) {
            if (Constants.DEBUG) {
                console.error(e);
            }
            this.sendError(this.id ? 'FORMS_ERROR_CREATING' : 'FORMS_ERROR_UPDATING', this.form, e);
        }
    },
    computed: {
        state() {
            return function (field) {
                return this.$v.form[field].$dirty ? !this.$v.form[field].$invalid : null;
            }
        },
        state2() {
            return function (v, field) {
                return v[field].$dirty ? !v[field].$invalid : null;
            }
        },
        errors() {
            return function (field) {
                const input = this.$v.form[field];
                const errors = Object.keys(input).filter(x => !x.startsWith('$'));
                const result = [];
                errors.forEach((error) => {
                    if (!input[error]) {
                        result.push({error: error.toUpperCase(), params: input.$params[error]});
                    }
                })
                return result;
            }
        },
        errors2() {
            return function (v, field) {
                const input = v[field];
                const errors = Object.keys(input).filter(x => !x.startsWith('$'));
                const result = [];
                errors.forEach((error) => {
                    if (!input[error]) {
                        result.push({error: error.toUpperCase(), params: input.$params[error]});
                    }
                })
                return result;
            }
        },
    },
    data() {
        return {
            id: undefined,
            saving: false,
            editPage: '',
        }
    }
}

export const DBG = {
    mounted() {

    },
    methods: {}
}

export const ProcessWithLoadingAndMessage = {
    mixins: [SendsMessages],
    methods: {
        async $p(processFlag, func, args) {
            try {
                let resp;
                this.flags[processFlag] = true;
                resp = await func(args);
                this.sendMessage(processFlag + '_SUCCESS_MESSAGE', {...args, ...resp});
            } catch (e) {
                if (Constants.DEBUG) {
                    console.error(e);
                }
                this.sendError(processFlag + '_ERROR_MESSAGE', {}, e);
            } finally {
                this.flags[processFlag] = false;
            }
        }
    },
    data() {
        return {
            flags: {}
        }
    }
}

export const VModel = {
    props: ['value'],
    data() {
        return {
            content: this.value
        }
    },
    methods: {
        handleInput(val) {
            this.$emit('input', val);
        }
    }
}


export default {
    DBG,
    IsBusy,
    VModel,
    LoadData,
    Form,
    Table,
    NumberFilter,
    DateFilter,
    CapitalFilter,
    ProcessWithLoadingAndMessage
};
