import Vue from 'vue'
import VueRouter from 'vue-router'
import Token from "@/token";
import Users from "@/users";
import eventBus from "@/events";
import Constants from "@/constants";

Vue.use(VueRouter)

const UserList = () => import('@/users/UserList');
const ProfileList = () => import('@/users/profiles/ProfileList');
const UserForm = () => import('@/users/UserForm');
const ProfileForm = () => import('@/users/profiles/ProfileForm');
const UserProfile = () => import('@/users/UserProfile');
const SessionList = () => import('@/users/sessions/SessionList');
const CustomerList = () => import('@/customers/CustomerList');
const QuoteList = () => import('@/quotes/QuoteList');
const QuoteForm = () => import('@/quotes/QuoteForm');
const ProjectList = () => import('@/projects/ProjectList');
const Login = () => import('../views/Login.vue');
const BasePage = () => import('@/views/BasePage');
const ApplicationLogList = () => import('@/log/ApplicationLogList');
const Recovery = () => import('@/views/Recovery');
const Reset = () => import('@/views/Reset');

const HomeComponent = () => import('@/home/HomeComponent');
// const PayrollReport = () => import('@/reports/PayrollReport');
// const CollectionReport = () => import('@/reports/CollectReport');

const routes = [
    {
        path: '/login',
        name: 'LOGIN_PAGE_TITLE',
        component: Login
    },
    {
        path: '',
        name: 'HOME_PAGE_TITLE',
        component: BasePage,
        children: [
            {path: '', name: 'HOME_PAGE', component: HomeComponent},
        ]
    },
    {
        path: '/recovery',
        name: 'PASSWORD_RECOVERY_PAGE_TITLE',
        component: Recovery
    },
    {
        path: '/password-reset',
        name: 'PASSWORD_RESET_PAGE_TITLE',
        component: Reset
    },
    {
        path: '/users',
        component: BasePage,
        children: [
            {path: '', name: 'USER_LIST_PAGE_TITLE', component: UserList},
            {path: 'register', name: 'USER_REGISTER_TITLE', component: UserForm},
            {path: ':id/edit', name: 'USER_EDIT_TITLE', component: UserForm}
        ]
    },
    {
        path: '/profile',
        component: BasePage,
        children: [
            {path: '', name: 'PROFILE_PAGE_TITLE', component: UserProfile},
        ]
    },
    {
        path: '/profiles',
        component: BasePage,
        children: [
            {path: '', name: 'PROFILE_LIST_PAGE_TITLE', component: ProfileList},
            {path: 'register', name: 'PROFILE_REGISTER_TITLE', component: ProfileForm},
            {path: ':id/edit', name: 'PROFILE_EDIT_TITLE', component: ProfileForm}
        ]
    },
    {
        path: '/sessions',
        component: BasePage,
        children: [
            {path: '', name: 'SESSION_LIST_PAGE_TITLE', component: SessionList}
        ]
    },
    {
        path: '/customers',
        component: BasePage,
        children: [
            {path: '', name: 'CUSTOMER_LIST_PAGE_TITLE', component: CustomerList}
        ]
    },
    {
        path: '/projects',
        component: BasePage,
        children: [
            {path: '', name: 'PROJECT_LIST_PAGE_TITLE', component: ProjectList}
        ]
    },
    {
        path: '/quotes',
        component: BasePage,
        children: [
            {path: '', name: 'QUOTE_LIST_PAGE_TITLE', component: QuoteList},
            {path: 'register', name: 'QUOTE_REGISTER_PAGE_TITLE', component: QuoteForm},
            {path: ':id/edit', name: 'QUOTE_EDIT_PAGE_TITLE', component: QuoteForm}
        ]
    },
    {
        path: '/logs',
        component: BasePage,
        children: [
            {path: '', name: 'LOG_LIST_PAGE_TITLE', component: ApplicationLogList}
        ]
    },
]

const router = new VueRouter({
    routes
});

router.beforeResolve(async (to, from, next) => {
    let permission;
    if (Token.isAuthenticated()) {
        let user = Users.currentUser;
        if (to.name === 'HOME_PAGE') {
            if (user && user.data && user.data.defaultPage) {
                next({name: user.data.defaultPage});
            }
        }
        switch (to.name) {
            case 'USER_LIST_PAGE_TITLE':
                permission = 'USER_LIST_ALL_PERMISSION';
                break;
            case 'SESSION_LIST_PAGE_TITLE':
                permission = 'SESSION_LIST_ALL_PERMISSION';
                break;
            case 'PROFILE_LIST_PAGE_TITLE':
                permission = 'PROFILE_LIST_ALL_PERMISSION';
                break;
            default:
                permission = undefined;
                break;
        }
        if (permission && !Token.hasPermission(permission)) {
            eventBus.$emit(Constants.EVENT_WHEN_MESSAGE_DISPLAY, 'USER_HAS_NO_PERMISSION', {page: to.name}, 'warning', undefined)
            next(from);
        } else {
            next();
        }
    } else {
        console.log('Not authenticated')
        const exceptions = [null, 'LOGIN_PAGE_TITLE', 'PASSWORD_RECOVERY_PAGE_TITLE', 'PASSWORD_RESET_PAGE_TITLE'];
        const name = to.name;
        if (exceptions.indexOf(name) === -1) {
            next({name: 'LOGIN_PAGE_TITLE'});
        } else {
            next();
        }
    }
});

export default router
