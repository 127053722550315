import Token from "@/token";

const Menu = {
    _items: [],

    get items() {
        this._items = [
            { id: 1, name: 'CATALOGS', items: [] }
        ]

        if(Token.isAuthenticated()) {
            if (Token.hasPermission('QUOTE_LIST_ALL_PERMISSION')) {
                this._items[0].items.push({
                    order: 0,
                    name: 'QUOTE_LIST_PAGE_TITLE',
                    icon: 'key',
                    label: 'QUOTES'
                });
            }

            if (Token.hasPermission('CUSTOMER_LIST_ALL_PERMISSION')) {
                this._items[0].items.push({
                    order: 1,
                    name: 'CUSTOMER_LIST_PAGE_TITLE',
                    icon: 'key',
                    label: 'CUSTOMERS'
                });
            }

            if (Token.hasPermission('PROJECT_LIST_ALL_PERMISSION')) {
                this._items[0].items.push({
                    order: 2,
                    name: 'PROJECT_LIST_PAGE_TITLE',
                    icon: 'key',
                    label: 'PROJECTS'
                });
            }

            if (Token.hasPermission('USER_LIST_ALL_PERMISSION')) {
                this._items[0].items.push({
                    order: 3,
                    name: 'USER_LIST_PAGE_TITLE',
                    icon: 'person',
                    label: 'USERS',
                    permission: '',
                },);
            }

            if (Token.hasPermission('LOG_REPORT_PERMISSION')) {
                this._items[0].items.push({
                    order: 4,
                    name: 'LOG_LIST_PAGE_TITLE',
                    icon: 'key',
                    label: 'LOG'
                });
            }

            if (Token.hasPermission('PROFILE_LIST_ALL_PERMISSION')) {
                this._items[0].items.push({
                    order: 5,
                    name: 'PROFILE_LIST_PAGE_TITLE',
                    icon: 'key',
                    label: 'PERMISSIONS'
                });
            }

            if (Token.hasPermission('SESSION_LIST_ALL_PERMISSION')) {
                this._items[0].items.push({
                    order: 6,
                    name: 'SESSION_LIST_PAGE_TITLE',
                    icon: 'key',
                    label: 'SESSIONS'
                });
            }

            const catalogs = this._items.map(x => x.name).indexOf('CATALOGS');
            if (this._items[catalogs].items.length <= 0) {
                this._items.splice(catalogs, 1);
            }

            return this._items;
        } else {
            return [];
        }
    }
};
export default Menu;
