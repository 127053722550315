import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {BootstrapVue, IconsPlugin, BootstrapVueIcons} from "bootstrap-vue";
import i18n from "@/i18n";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import {Vuelidate} from "vuelidate";
import './global.css';
import {hasPermission} from "@/directives";
import JsonExcel from "vue-json-excel";

String.prototype.plural = function () {
    const lastChar = this.charCodeAt(this.length - 1);
    const isUpper = lastChar > 64 && lastChar < 91;
    const isLower = lastChar > 96 && lastChar < 123;
    const isValid = isLower || isUpper;
    if (isValid) {
        if (isUpper) {
            if (lastChar === 89) {
                return this.slice(0, this.length - 1) + 'IES';
            } else if (lastChar === 83) {
                return this + 'ES';
            } else {
                return this + 'S';
            }
        } else {
            if (lastChar === 121) {
                return this.slice(0, this.length - 1) + 'ies';
            } else if (lastChar === 115) {
                return this + 'es';
            } else {
                return this + 's';
            }
        }
    }
    return this;
}

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);

Vue.component('downloadExcel', JsonExcel);

Vue.directive('hasPermission', hasPermission)

new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
