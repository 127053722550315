import http from "@/http";
import eventBus from "@/events";

const Companies = {
    currentCompany: null,
    findAll: async function () {
        const resp = await http("/companies");
        return resp.data;
    },
    setCurrentCompany(company) {
        this.currentCompany = company;
        localStorage.setItem('open_maggot_company', company);
        eventBus.$emit('COMPANY_CHANGED', company);
    }
};

export default Companies;
