import Token from "@/token";

export const hasPermission = {
    bind: function (el, bind) {
        const permission = bind.value;
        el.hidden = !Token.hasPermission(permission);
    }
}

export default {pin: hasPermission};
