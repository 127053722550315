<template>
  <div id="app-navbar">
    <b-navbar type="dark" variant="dark">
      <b-button class="mr-1" variant="dark" @click="goBack">
        <b-icon-chevron-left></b-icon-chevron-left>
      </b-button>
      <b-button variant="dark" v-b-toggle.menu-1>
        <b-icon-list></b-icon-list>
      </b-button>

      <b-navbar-brand>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <b-icon-person-circle class="mr-1"></b-icon-person-circle>
              <em>{{ user.fullName }}</em>
            </template>
            <b-dropdown-item @click="profile" href="#">{{ $t('PROFILE_LABEL') | capitalize }}</b-dropdown-item>
            <b-dropdown-item @click="showCompanyChangeModal" href="#">{{ $t('CHANGE_COMPANY_LABEL') | capitalize }}</b-dropdown-item>
            <b-dropdown-item @click="logout" href="#">{{ $t('LOGOUT_LABEL') | capitalize }}</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-modal id="company-change" :title="$t('SELECT_COMPANY_TITLE') | capitalize" @ok="setCurrentCompany">
      <div v-if="loadingCompanies" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('COMPANIES')})  | capitalize }}</strong>
      </div>
      <div v-else>
        <b-form>
          <b-form-group :label="$t('COMPANY_NAME_LABEL') | capitalize">
            <b-select v-model="selectedCompany">
              <b-select-option v-for="company in companies" :key="company.database" :value="company.database">
                {{company.name}}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </div>

</template>

<script>
import Users from "@/users";
import {CapitalFilter, Company, SendsMessages} from "@/mixins";
import Companies from "@/companies";

export default {
  name: "app-menu",
  mixins: [CapitalFilter, SendsMessages, Company],
  mounted() {
    this.user = Users.currentUser;
    if(this.$route.name === 'LOGIN_PAGE_TITLE') {
      this.$router.push({name: 'HOME_PAGE'})
    }

    Companies.setCurrentCompany(localStorage.getItem('open_maggot_company'))
    this.currentCompany$ = Companies.currentCompany;

    if(!this.hasCompany) {
      this.showCompanyChangeModal();
    }

    this.loadCompanies();
  },
  methods: {
    async loadCompanies() {
      try {
        this.loadingCompanies = true;
        this.companies = await Companies.findAll();
      } catch (e) {
        console.error(e);
        this.sendError('ERROR_LOADING_COMPANIES', {}, e);
      } finally {
        this.loadingCompanies = false;
      }
    },
    goBack() {
      this.$router.back();
    },
    async logout() {
      await Users.logout();
      await this.$router.push({path: '/login'})
    },
    profile() {
      this.$router.push({path: '/profile'});
    },
    async showCompanyChangeModal() {
      this.selectedCompany = this.currentCompany;
      this.$bvModal.show('company-change');
    },
    setCurrentCompany() {
      Companies.setCurrentCompany(this.selectedCompany);
    }
  },
  computed: {
    companyName() {
      if(this.currentCompany && this.companies && this.companies.length > 0) {
        const idx = this.companies.map(x => x.database).indexOf(this.currentCompany);
        if(idx > -1) {
          return this.companies[idx].name;
        } else {
          return this.$t('UNKNOWN_COMPANY_SELECTED');
        }
      } else {
        return this.$t('NO_COMPANY_SELECTED');
      }
    }
  },
  data() {
    return {
      loadingCompanies: false,
      companies: [],
      selectedCompany: '',
      user: {fullName: this.$t('NO_USER_LABEL')}
    }
  }
}
</script>

<style scoped>

</style>
