const Constants = {
    DEBUG: !!process.env.VUE_APP_DEBUG,
    CURRENCY_FORMAT: process.env.VUE_APP_CURRENCY_FORMAT,
    NUMBER_FORMAT: process.env.VUE_APP_NUMBER_FORMAT,
    DATE_FORMAT: process.env.VUE_APP_DATE_FORMAT,
    PERCENTAGE_FORMAT: process.env.VUE_APP_PERCENTAGE_FORMAT,
    EVENT_WHEN_USER_COMES_BACK: 'EVENT_WHEN_USER_COMES_BACK',
    EVENT_WHEN_AUTHENTICATION_EXPIRES: 'EVENT_WHEN_AUTHENTICATION_EXPIRES',
    EVENT_INVALID_CREDENTIALS: 'EVENT_INVALID_CREDENTIALS',
    EVENT_COULD_NOT_GET_CURRENT_USER: 'EVENT_COULD_NOT_GET_CURRENT_USER',
    EVENT_COULD_NOT_GET_QUOTES: 'EVENT_COULD_NOT_GET_QUOTES',
    EVENT_COULD_NOT_GET_COMPANIES: "EVENT_COULD_NOT_GET_COMPANIES",
    EVENT_WHEN_NO_COMPANIES: 'EVENT_WHEN_NO_COMPANIES',
    EVENT_WHEN_COMPANY_CHANGES: "EVENT_WHEN_COMPANY_CHANGES",
    EVENT_WHEN_REQUEST_COMPANY_CHANGE: "EVENT_WHEN_REQUEST_COMPANY_CHANGE",
    EVENT_WHEN_DATA_SAVED: 'EVENT_WHEN_DATA_SAVED',
    EVENT_WHEN_MESSAGE_DISPLAY: 'EVENT_WHEN_MESSAGE_DISPLAY',
    EVENT_WHEN_LOGOUT: 'EVENT_WHEN_LOGOUT',
    EVENT_ON_USER_LIST: 'EVENT_ON_USER_LIST',
    EVENT_ON_PROFILE_LIST: 'EVENT_ON_PROFILE_LIST',
    EVENT_ON_SESSION_LIST: 'EVENT_ON_SESSION_LIST',
    EVENT_ON_PERMISSION_LIST: 'EVENT_ON_PERMISSION_LIST'
};

export default Constants;
